/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Tellja_Container {
  position: relative;

  :global {

    .tWidgetButtonStyle {
      background: $purpleDarker;
      border-radius: 8px;
      display: block;
      margin: 0 auto;
      padding: 8px 16px 10px;
      position: relative;
      width: 100%;

      img {
        left: 0;
        position: absolute;
        top: 0;
      }

      &:before {
        color: $asamWhite;
        content: 'Freund:innen empfehlen';
        display: block;
        font-size: $fontSize-l;
        font-weight: $font-weight-m;
        line-height: 32px;
        text-align: center;
      }
    }
  }
}
